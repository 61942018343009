<template>    
  <div class="background_basic">          
    <v-card class="ma-3" >            
      <v-toolbar dense flat color="white">      
        <v-toolbar-title class="grey--text text--darken-3">
          <v-icon class="mr-2">mdi-account-arrow-right</v-icon>작업자관리
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">refresh</v-icon>초기화
        </v-btn>
        <v-btn color="blue" text @click="ExcelExpert('작업자관리')" class = "ml-2 font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">mdi-microsoft-excel</v-icon>엑셀
        </v-btn>
        <v-spacer></v-spacer>
        
        <v-btn v-if="(UserInfo.CorpId=='NONE' || UserInfo.CorpId == 'SKMR')" color="blue" text @click="GetCorpMember()" class="font-weight-bold text-subtitle-1" :disabled="loading3">
          <v-icon big class="mr-2">mdi-cloud-download</v-icon>데이터불러오기
        </v-btn>
        
        <v-btn color="blue" text @click="ClearDialog(); isEditMode=false; dialog = true" class="font-weight-bold text-subtitle-1" :disabled="loading3">         
          <v-icon big class="mr-2">mdi-account</v-icon>신규등록
        </v-btn>
        <v-dialog v-model="dialog" max-width="600px">          
          <v-card ref="form">
            <v-card-title >
              <span class="font-weight-bold">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="ma-0">
                  
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense v-model="editedItem.cUserId" label="ID" :disabled="isEditMode"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <div class="d-flex">
                      <v-select class="mr-2" dense label="유형" :items="zDeviceTypeInfo" v-model="editedItem.cDeviceTypeId" item-value="sId" item-text="sNm" :disabled="isEditMode"></v-select>
                      <img height="30" :src="require('@/assets/Device/D'+editedItem.cDeviceTypeId+'.svg')"/> 
                    </div>
                  </v-col>
                  <v-col v-if="isEditMode" cols="12" sm="6" md="6">
                    <v-text-field dense v-model="editedItem.cCorpNm" label="업체명" disabled></v-text-field>
                  </v-col>
                  <v-col v-if="!isEditMode" cols="12" sm="6" md="6">
                    <v-select dense v-model="editedItem.cCorpId" class="mb-n5" label="업체" :items="zCorp" item-value="sId" item-text="sNm"></v-select>   
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense v-model="editedItem.cUserNm" label="이름"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense v-model="editedItem.cTel" label="연락처"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <eCalendar :sNowSelectDate="editedItem.cBirth" Caption="생년월일"  @onChaange="onCalChange"></eCalendar>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-select dense label="구분" :items="zUserRoleType" v-model="editedItem.cLev" item-value="sId" item-text="sNm"></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select dense label="작업자구분" disabled :items="zWorkType" v-model="cWorkType"></v-select>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense v-model="editedItem.cUpdateUserId" label="최종수정자" disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense v-model="editedItem.cUpdateDateTime" label="최종수정일" disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense v-model="editedItem.cCreateUserId" label="최초생성자" disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense v-model="editedItem.cCreateDateTime" label="최초생성일" disabled></v-text-field>
                  </v-col>                  
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>                
              <v-spacer></v-spacer>              
              <v-btn color="blue darken-2" text @click="save" :disabled="loading" class="font-weight-bold text-subtitle-1">
                <v-icon big class="mr-2">save</v-icon>저장
              </v-btn>
              <v-btn color="grey darken-2" darken-2 text @click="close" :disabled="loading" class="font-weight-bold text-subtitle-1">
                <v-icon big class="mr-2">cancel</v-icon>취소
              </v-btn>              
            </v-card-actions>              
            <v-progress-linear :active="loading" :indeterminate="loading" absolute bottom color="blue accent-4"></v-progress-linear>
          </v-card>
        </v-dialog>
        <v-progress-linear :active="loading3" :indeterminate="loading3" absolute bottom color="blue accent-4"></v-progress-linear>    
      </v-toolbar>
    
      <v-divider></v-divider>

      <v-card flat class="d-flex ml-3 mr-3">          
        <v-text-field
          v-model="cNowSearchKeyWord"
          class="ml-0 mb-n4"
          label="검색어"
          placeholder="관리번호 또는 이름 또는 업체명 또는 연락처 또는 직책 입력"
          persistent-placeholder
          style="max-width:500px;"
          Regular
          v-on:keyup.enter="Search()"
        ></v-text-field>
        <v-btn icon class="ml-n8 mt-3 text-subtitle-1" @click="Search()">
          <v-icon>search</v-icon>
        </v-btn>
        <v-switch color="grey darken-3" v-model="bTagWith" class="ml-5 mt-3 mb-n4" label="TAG 포함" @change="Search()"></v-switch>
      </v-card>
      <v-divider></v-divider>
      <v-data-table         
        :items-per-page="15" 
        fixed-header         
        height="calc(100vh - 250px)" 
        :headers="bTagWith?headers_tag:headers" 
        :items="Records"
        @dblclick:row="handleDoubleClick">
        <template v-slot:item.action="{ item }">
          <v-icon big class="mr-0" @click="editItem(item)">edit</v-icon>
          <v-icon big @click="deleteItem(item)">delete</v-icon>
          <!-- <v-icon big class="mr-0" @click="editItem(item)" :disabled="!ProgramPermissionInfo.okU">edit</v-icon>
          <v-icon big @click="deleteItem(item)" :disabled="!ProgramPermissionInfo.okD">delete</v-icon> -->
        </template>        
        <template v-slot:item.bUse="{ item }">
          <v-switch color="grey darken-3" v-model="item.bUse" class="mt-n2 mb-n7" @change="changeUse(item)"></v-switch>           
        </template>
        <template v-slot:item.bLocked="{ item }">
          <v-switch color="grey darken-3" v-model="item.bLocked" class="mt-n2 mb-n7" @change="changeLocked(item)"></v-switch>           
        </template>          
        <template v-slot:item.cLev="{ item }">
          <v-chip v-if="item.cLev == 'N'" label small color="grey lighten-3" >일반</v-chip>
          <v-chip v-if="item.cLev == 'M'" label small color="blue darken-1" dark>관리자</v-chip>
        </template>
        <template v-slot:item.cPwdFailCnt="{ item }">
          <div v-if="item.cPwdFailCnt == '0'">
            {{item.cPwdFailCnt}}
          </div>
          <div v-else>
            <v-btn small color="grey darken-3" dark @click="PWCntInitial(item)" dense> <v-icon class="mr-1" small>refresh</v-icon>초기화 ({{item.cPwdFailCnt}})</v-btn>
          </div>
          
        </template>

        <template v-slot:item.cDeviceTypeNm="{ item }">
          <div class="d-flex text--center" style="justify-content: center;">
            <img class="mt-1 mr-2" height="30" :src="require('@/assets/Device/D'+item.cDeviceTypeId+'.svg')"/> <div class="mt-2">{{item.cDeviceTypeNm}}</div>
          </div>
        </template>        



        <template v-slot:item.cMapCds="{ item }">
          <v-btn small @click="GetMapItem(item)" :disabled="!ProgramPermissionInfo.okU" color="grey darken-2" dark><v-icon class="mr-1">map</v-icon>맵
          </v-btn>          
        </template>


        <template v-slot:item.cMapNms="{ item }">
          <v-chip label small v-if ="item.cMapNms != ''" color="grey lighten-3">{{item.cMapNms}}</v-chip>
          <v-chip label small v-else color="red lighten-2" dark>선택안됨</v-chip>
        </template>   

        <template v-slot:item.cIsEmployed="{ item }">
          <v-chip label small v-if ="item.cIsEmployed == 'Y'" color="grey lighten-3">재직</v-chip>
          <v-chip label small v-else color="red lighten-2" dark>퇴사</v-chip>
        </template>      
        <template v-slot:no-data>
          <p>No data available.</p>
        </template>
      
      </v-data-table>
    
    </v-card>
    <MsgBox ref="MsgBoxRef" @onMsgBox="onMsgBoxFunc" />    
    <GetSelMap ref="GetSelMapRef" @onGetMap="onGetMap" />       
  </div>    
</template>

<script>
import MsgBox from "@/components/MsgBox.vue";
import eCalendar from "@/components/eCalendar.vue";
import BasicInfo from "../BasicInfo.js";
import Util from "../Util.js";
import axios from "axios";
import { mapState } from "vuex";
import XLSX from 'xlsx';
import EventBus from '@/eventBus.js';
import GetSelMap from "@/components/GetSelMap.vue";
import SHA256 from "sha256"; 


export default {
  components: {
    MsgBox,  
    eCalendar,
    GetSelMap,
  },
  data: () => ({
    bTagWith : false,
    isGroupALL : false,    
    cGroup : "ALL", 
    cNowSearchKeyWord : "",
    loading : false,
    loading3 : false,
    isEditMode : false,
    iDelIndex: 0,
    dialog: false,
    model : 0,
    sDate : "",        
    cWorkType : "작업자",    
    zWorkType : ["작업자"],
    zPartNm: [{value:"P001", name:"개발팀"}, {value:"P002", name:"영업팀"}, {value:"P003", name:"총무팀"}, {value:"P004", name:"기획팀"}],  
    headers: [      
      { class: "font-weight-bold subtitle-2", text: "ID", value: "cUserId",  },
      { class: "font-weight-bold subtitle-2", text: "이름", value: "cUserNm", align: "center" , width:"150"},         
      { class: "font-weight-bold subtitle-2", text: "유형", value: "cDeviceTypeNm", align: "center" , width:"200"},         
      { class: "font-weight-bold subtitle-2", text: "업체명", value: "cCorpNm", align: "center", width:"150"},         
      { class: "font-weight-bold subtitle-2", text: "연락처", value: "cTel", align: "center", width:"150"},         
      { class: "font-weight-bold subtitle-2", text: "구분", value: "cLev", align: "center" },      
      { class: "font-weight-bold subtitle-2", text: "맵선택", value: "cMapCds", align: "center" },    
      { class: "font-weight-bold subtitle-2", text: "맵", value: "cMapNms", align: "left" },            
      { class: "font-weight-bold subtitle-2", text: "재직", value: "cIsEmployed", align: "center"},
      { class: "font-weight-bold subtitle-2", text: "잠금", value: "bLocked", align: "center" , width:"30"},         
      { class: "font-weight-bold subtitle-2", text: "사용", value: "bUse", align: "center" , width:"30"},         
      { class: "font-weight-bold subtitle-2", text: "로그인 실패 횟수 초기화", value: "cPwdFailCnt", align: "center",  sortable: false},            
      { class: "font-weight-bold subtitle-2", text: "작업", value: "action", align: "center", sortable: false},
    ],
    headers_tag: [      
      { class: "font-weight-bold subtitle-2", text: "ID", value: "cUserId",  },
      { class: "font-weight-bold subtitle-2", text: "이름", value: "cUserNm", align: "center" , width:"150"},         
      { class: "font-weight-bold subtitle-2", text: "유형", value: "cDeviceTypeId", align: "center" , width:"150"},         
      { class: "font-weight-bold subtitle-2", text: "유형", value: "cDeviceTypeNm", align: "center" , width:"200"},         
      { class: "font-weight-bold subtitle-2", text: "업체명", value: "cCorpNm", align: "center", width:"150"},         
      { class: "font-weight-bold subtitle-2", text: "연락처", value: "cTel", align: "center", width:"150"},         
      { class: "font-weight-bold subtitle-2", text: "구분", value: "cLev", align: "center" },      
      { class: "font-weight-bold subtitle-2", text: "태그", value: "cTagId", align: "center", width:"150"},   
      { class: "font-weight-bold subtitle-2", text: "맵선택", value: "cMapCds", align: "center" },    
      { class: "font-weight-bold subtitle-2", text: "맵", value: "cMapNms", align: "left" },            
      { class: "font-weight-bold subtitle-2", text: "재직", value: "cIsEmployed", align: "center"},
      { class: "font-weight-bold subtitle-2", text: "잠금", value: "bLocked", align: "center" , width:"30"},         
      { class: "font-weight-bold subtitle-2", text: "사용", value: "bUse", align: "center" , width:"30"},         
      { class: "font-weight-bold subtitle-2", text: "로그인 실패 횟수 초기화", value: "cPwdFailCnt", align: "center",  sortable: false},      
      { class: "font-weight-bold subtitle-2", text: "작업", value: "action", align: "center", sortable: false},
    ],
    Records: [],
    editedIndex: -1,
    // userId(스토어), cDel(golang), cEtc(golang)만 제외하고 테이블의 항목을 설정함 
    editedItem: {
      cDeviceTypeId : "User", 
      cUserId: "",
      cUserNm: "",
      cPwd: "",
      cInfo: "",
      cLev: "N",
      cCorpId: "NONE",
      cCorpNm: "",      
      cPartNm: "",      
      cIsEmployed: "",
      cUnregDate: null,
      cUserRoleId: "",
      cMapTreeIdx: "",
      cEmail: "",
      cBirth: "",
      cPwdChangeDate: "",
      cPwdExpireDate: "",
      cPwdFailCnt: 0,
      cLocked: "N",
      cUse: "",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    },
    deletedItem: {
      cDeviceTypeId : "User", 
      cUserId: "",
      cUserNm: "",
      cPwd: "",
      cInfo: "",
      cLev: "N",
      cCorpId: "NONE",
      cCorpNm: "",      
      cPorpNm: "",
      cIsEmployed: "",
      cUnregDate: null,
      cUserRoleId: "",
      cMapTreeIdx: "",
      cEmail: "",
      cBirth: "",
      cPwdChangeDate: "",
      cPwdExpireDate: "",
      cPwdFailCnt: 0,
      cLocked: "N",
      cUse: "",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    },
    defaultItem: {
      cDeviceTypeId : "User", 
      cUserId: "",
      cUserNm: "",
      cPwd: "",
      cInfo: "",
      cLev: "N",
      cCorpId: "NONE",
      cCorpNm: "",      
      cPorpNm: "",
      cIsEmployed: "",
      cUnregDate: null,
      cUserRoleId: "",
      cMapTreeIdx: "",
      cEmail: "",
      cBirth: "",
      cPwdChangeDate: "",
      cPwdExpireDate: "",
      cPwdFailCnt: 0,
      cLocked: "N",
      cUse: "",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    }
  }),

  computed: {
    ...mapState(["UserInfo", "ProgramPermissionInfo", "zUserRoleType", "zCorp", "zDeviceTypeInfoAll", "zDeviceTypeInfo"]),
    formTitle() {
      return this.editedIndex === -1 ? "신규 등록" : "항목 수정";
    },
  },

  created() {
    this.$store.state.GetCorpInfo();    
    this.$store.state.GetDeviceTypeInfo();    

    this.initialize();
  },

  methods: {    
    onCalChange(s){      
      this.editedItem.cBirth = s;      
    },

    initialize() {
      this.cNowSearchKeyWord = "";      
      this.Search();
      this.model = 0;
    },

    ClearDialog() {      
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem.cGroupId = this.$store.state.UserInfo.GroupId;

      if (this.zCorp.length > 0){
        this.editedItem.cCorpId = this.zCorp[0].sId;
        this.editedItem.cCorpNm = this.zCorp[0].sNm;  
      }      
      this.editedIndex = -1;                      
    },

    handleDoubleClick(value, items) { 
       if (!this.ProgramPermissionInfo.okU) return;
       this.editItem(items.item);  
    },

    // 장비등록 목록을 조회함. (검색어를 같이 보냄)
    Search() {
      this.loading = true;
      this.Records = [];
      var posData = {
        cNowSearchKeyWord : this.cNowSearchKeyWord,        
        cCorpNm : this.UserInfo.CorpNm,
        cNowSiteId : this.UserInfo.NowSiteId,
        cTagWith : "N",
      };

      if (this.bTagWith) {
        posData.cTagWith = "Y";
      }
      axios
        .post(BasicInfo.UIL_API + "GetWorker", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {       
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
             
          if (res.data.ResultCd === "00") {
            this.Records = res.data.Result;
            
            for (var i = 0; i < this.Records.length; i++) {           
              this.Records[i].bLocked = this.Records[i].cLocked == 'Y';          
              this.Records[i].bUse = this.Records[i].cUse == "Y";         
              this.Records[i].zMap = res.data.Result[i].cMapCds.split(",");     
            }
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;  
          
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },

    // 비밀번호실패 횟수초기화
    PWCntInitial(item) {
      this.editedIndex = this.Records.indexOf(item);   
      this.editedItem = Object.assign({}, item);   
      var posData = {
        cNowSiteId : this.UserInfo.NowSiteId,
        cEditUserId: this.$store.state.UserInfo.UserId,
        cUserId : this.editedItem.cUserId, 
        cPwdFailCnt : 0,
      };
      console.log(posData);
      axios
        .post(BasicInfo.UIL_API + "PWCntInitial", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            this.dialog = false;
            this.Records[this.editedIndex].cPwdFailCnt = "0";
            EventBus.$emit("onShowMsg",false, "저장되었습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },


    
    save() {
      if (this.editedItem.cUserId == "" ) {
        EventBus.$emit("onShowMsg",true, "사용자ID는 필수입력 항목입니다.");
        return;
      }     

      if (this.editedItem.cCorpId == "NONE" ) {
        EventBus.$emit("onShowMsg",true, "업체ID는 필수입력 항목입니다.");
        return;
      }     
      
      var posData = {
        cNowSiteId : this.UserInfo.NowSiteId,
        cDeviceTypeId: this.editedItem.cDeviceTypeId,
        cUpdateUserId: this.$store.state.UserInfo.UserId,
        cUserId: this.editedItem.cUserId,                        
        cUserNm: this.editedItem.cUserNm,                        
        cCorpId: this.editedItem.cCorpId,            
        cNewUserId: "",
        cEditUserId: "",        
        cPwd : SHA256(this.editedItem.cUserId + "1Qwer1234!" + "@" + this.editedItem.cUserId),
        cBirth: this.editedItem.cBirth,
        cTel: this.editedItem.cTel,
        cLev: this.editedItem.cLev,
        cIsEmployed : "Y",
        PwdFailCnt : "0",
        cGroupId : this.editedItem.cGroupId,
      }
      if (this.isEditMode) {
        posData.cEditUserId = this.editedItem.cUserId;
      } else {
        posData.cNewUserId = this.editedItem.cUserId;
      }

      this.loading = true;
      axios
        .post(BasicInfo.UIL_API + "SetWorker", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            if (this.isEditMode) {
              this.editedItem.cApId = res.data.Result[0].cApId;
              this.editedItem.cUpdateDateTime = res.data.Result[0].cUpdateDateTime;
              Object.assign(this.Records[this.editedIndex], this.editedItem);
            } else {
              this.editedItem.cApId = res.data.Result[0].cApId;              
              this.editedItem.cCreateDateTime = res.data.Result[0].cUpdateDateTime;
              this.editedItem.cUpdateDateTime = res.data.Result[0].cUpdateDateTime;
              this.editedItem.id = this.Records.length +1;     // 자동카운트시
              this.Records.push(this.editedItem);
            }
            this.dialog = false;
            EventBus.$emit("onShowMsg",false, "저장되었습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },
    GetCorpMember() {
      var posData = {}
      
      this.loading3 = true;
      axios
        .post(BasicInfo.UIL_API + "GetCorpMember", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {        
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
            
          if (res.data.ResultCd === "00") {            
            EventBus.$emit("onShowMsg",false, "불러오기를 성공하였습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading3 = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading3 = false;
        });
    },

    changeUse(item){      
      //console.log("changeUse", item.cUserId, item.bUse);
      item.cUse = "N"
      if (item.bUse){ item.cUse = "Y" }
      
      var posData = {
        cNowSiteId : this.UserInfo.NowSiteId,
        cUserId : this.$store.state.UserInfo.UserId,
        cEditUserId : item.cUserId,
        cUse : item.cUse,
      }
      
      this.loading = true;
      axios
        .post(BasicInfo.UIL_API + "SetUserInfoUse", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {          
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {            
            EventBus.$emit("onShowMsg",false, "저장했습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },

    changeLocked(item){            
      item.cLocked = "N"
      if (item.bLocked){ item.cLocked = "Y" }
      
      var posData = {
        cNowSiteId : this.UserInfo.NowSiteId,
        cUserId : this.$store.state.UserInfo.UserId,
        cEditUserId : item.cUserId,
        cLocked : item.cLocked,
      }
      
      this.loading = true;
      axios
        .post(BasicInfo.UIL_API + "SetUserInfoLocked", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {          
          if (res.data.ResultCd === "00") {            
            EventBus.$emit("onShowMsg",false, "저장했습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },
    
    // 신규등록/수정 다이올로그 취소시 닫음 (0.3초가 지나면 디폴트값으로 넣어줌)
    close() {      
      this.dialog = false;
      this.isEditMode = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    // 수정 다이올로그 생성 (테이블상의 연필모양 클릭시, isEditMode는 수정못함)
    editItem(item) {
      this.isEditMode = true;
      this.editedIndex = this.Records.indexOf(item);   
      this.editedItem = Object.assign({}, item);       
      this.dialog = true;
    },

    // 삭제알람 다이올로그 띄우기 (클릭시 DB에 등록 진행: onMsgBoxFunc와 연동)
    deleteItem(item) {
      this.iDelIndex = this.Records.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.$refs.MsgBoxRef.RunMsgBoxShow(
        "확인",
        "삭제하시겠습니까? \r\n 삭제하시면 복원되지 않습니다. \r\n[" +
          this.deletedItem.cUserId + "/" + this.deletedItem.cUserNm + "]"
      );
    },

    // (deleteItem()와 연동) DB에서 삭제하기 (del='N'으로 바꿈), web 화면상에 iDelIndex로 부터 첫번째 행을 제거함
    // onMsgBox 값 (취소시:false, 저장시:true)
    onMsgBoxFunc: function(result) {
      if (result === true) {
        //삭제
        var posData = {
          UserId: this.$store.state.UserInfo.UserId,
          cEditUserId: this.deletedItem.cUserId,
        };
        axios
          .post(BasicInfo.UIL_API + "DelUser", posData, {
            headers: {
              Authorization: this.$store.state.UserInfo.Token
            }
          })
          .then(res => {
            if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
            if (res.data.ResultCd === "00") {
              this.Records.splice(this.iDelIndex, 1); 
            } else {
              EventBus.$emit("onShowMsg",true, res.data.Msg);
            }
          })
          .catch(err => {
            EventBus.$emit("onShowMsg",true, ""+err);
          });
      }
    },
    
    ExcelExpert(name){
      var r = Util.GetExcel(this.Records, this.bTagWith?this.headers_tag:this.headers);
      // var r = Util.GetExcel(this.Records, this.headers);
      var animalWS = XLSX.utils.json_to_sheet(r)       
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, animalWS, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb, name + "-" + Util.sNowDate() + '.xlsx'); // name of the file is 'book.xlsx'
    },

    GetMapItem(item) {
      this.isEditMode = true;
      this.editedIndex = this.Records.indexOf(item);   
      this.editedItem = Object.assign({}, item); 
      this.$refs.GetSelMapRef.show(this.editedItem.zMap); 
    },

    onGetMap(zSelect, cMapNms, cMapCds) {
      this.editedItem.cMapCds = cMapCds;
      this.editedItem.cMapNms = cMapNms;
    
      var posData = {        
        cNowSiteId : this.UserInfo.NowSiteId,
        cUpdateUserId: this.$store.state.UserInfo.UserId,
        cUserId : this.editedItem.cUserId, 
        cMapCds : this.editedItem.cMapCds,
        cMapNms : this.editedItem.cMapNms,
      };

      
      console.log(zSelect);
      axios
        .post(BasicInfo.UIL_API + "SetUserMap", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {          
            Object.assign(this.Records[this.editedIndex], this.editedItem);
          } else {
            EventBus.$emit("onShowMsg",true, res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },
  }
};
</script>

